import React, { useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  SwipeableDrawer,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // Icono para el botón de scroll




// Definir las categorías y herramientas
const toolsData = {
  analize: [
    { name: '🐀 Bastion', url: 'https://bastion.phii.app', description: 'Domain checker' },
    { name: '🎯 Lastlogin', url: 'https://phii.app/tools/lastlogin.php', description: 'Organiza la información de lastlogin' },
    { name: '📃 Organizador de login_log', url: 'https://phii.app/tools/cpanel-log.php', description: 'Analiza y organiza fecha/hora/servicio de login_log' },
  ],
  generator: [
    { name: '🛫 Easy Migration', url: 'https://phii.app/tools/migraciones/', description: 'Generar nota de migración - by Mr. Green' },
    { name: '🛜 DNS remoto - Email Local', url: 'https://phii.app/tools/remote-email.php', description: 'Crea registros DNS para configurar correos electrónicos en zonas DNS externas' },
    { name: '📨 OXCS', url: 'https://phii.app/tools/oxcs.php', description: 'Registros DNS para OXCS' },
    { name: '🔄 Cambio D. Principal', url: 'https://phii.app/tools/cd.php', description: 'Genera el texto para cambio de dominio principal.' },
    { name: '🔒 Password Generator', url: 'https://phii.app/tools/pass.php', description: '12, 16, 20 y 32 caracteres' },
  ],
  consult: [
    { name: '💻 Who is', url: 'https://who.phii.app', description: 'Consulta whois' },
    { name: '🌎 IP', url: 'https://phii.app/tools/ip.php', description: 'Consulta tu IP, la ubicación geográfica de otras IP y estado en abusedb' },
    { name: '📃 Casos específicos (+ Macros)', url: 'https://docs.google.com/document/u/2/d/1PCB-kxuI0xONKV5YXAqjnOTD5uBirqrJCkMOCEjud74/edit', description: 'Guía para casos específicos y macros en Google Docs' },
    { name: '📡 Métodos HTTP', url: 'https://phii.app/tools/metods.php', description: 'Consulta los métodos http validos en una URL' },
  ],
  dev: [
    { name: '🎓 Fix Moodle', url: 'https://cloud.phii.app/index.php/s/XyBAxSDirPxB258/download/fixmoodle.php', description: ' disk_free_space function is disabled || ERR_TOO_MANY_REDIRECTS || HTTP a HTTPS  - Colocar en carpeta donde esta instalado moodle y ejecutar (moodle)/fixmoodle.php' },
    { name: '⚙️ First Step Wordpress', url: 'https://cloud.phii.app/index.php/s/yxnP8MeLg75EMNw/download/auto-installer.zip', description: 'WordPress auto-installer (WP Plugin)' },
    { name: '📝Only Custom Code', url: 'https://cloud.phii.app/index.php/s/cFFHbgLirWiwRpm/download/phii-custom-code.zip', description: 'Agrega código en el header/body/footer WordPress (WP Plugin)' },

  ],
  monitor: [
    { name: '🧸 Kuma', url: 'https://kuma.phii.app', description: '------' },
    { name: '🐑 Lamb', url: 'https://lamb.phii.app', description: '------' },
  ],
};

const externalTools = [
  { name: '🥬 UNO Lignum', url: 'https://uno.lignum.com.mx/' },
  { name: '😾NBX Toolbox', url: 'https://support.nbxtools.cloud/' },
];


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#6d28d9', // Púrpura profundo para elementos principales
      light: '#8b5cf6', // Púrpura más claro para acentos
    },
    secondary: {
      main: '#3b82f6', // Azul brillante para acentos secundarios
    },
    background: {
      default: '#030712', // Negro espacial profundo para el fondo principal
      paper: '#0f172a', // Azul muy oscuro para componentes
    },
    text: {
      primary: '#f1f5f9', // Blanco suave para el texto principal
      secondary: '#94a3b8', // Gris azulado claro para el texto secundario
    },
    stars: {
      bright: '#eab308', // Amarillo brillante para estrellas destacadas
      dim: '#6b7280', // Gris para estrellas más tenues
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `
            radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
            radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
            radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
            radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px)
          `,
          backgroundSize: '550px 550px, 350px 350px, 250px 250px, 150px 150px',
          backgroundPosition: '0 0, 40px 60px, 130px 270px, 70px 100px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1e1b4b', // Azul profundo para el drawer
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1b4b', // Azul profundo para la barra superior
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(15, 23, 42, 0.8)', // Fondo semi-transparente para las tarjetas
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(99, 102, 241, 0.2)', // Borde suave con un toque de neón
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#f1f5f9', // Texto claro para los botones
        },
        contained: {
          backgroundColor: '#4c1d95', // Púrpura oscuro para botones principales
          '&:hover': {
            backgroundColor: '#6d28d9', // Púrpura más claro al pasar el mouse
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(99, 102, 241, 0.2)', // Azul neón semi-transparente para el ítem seleccionado
          },
          '&:hover': {
            backgroundColor: 'rgba(99, 102, 241, 0.1)', // Un poco más claro al pasar el mouse
          },
        },
      },
    },
  },
});






// Componente para cada herramienta
const ToolCard = ({ tool }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom variant="h6" component="div">
        {tool.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {tool.description}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary" variant="contained" href={tool.url} rel="noopener noreferrer">
        Abrir
      </Button>
    </CardActions>
  </Card>
);



// Componente principal
const ToolsDashboard = () => {
  const [currentCategory, setCurrentCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false); // Estado para el botón de scroll
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const filteredTools = useMemo(() => {
    let tools = currentCategory === 'all'
      ? Object.values(toolsData).flat()
      : toolsData[currentCategory] || [];

    return tools.filter(tool =>
      tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tool.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [currentCategory, searchTerm]);

  // Manejar el scroll
  const handleScroll = () => {
    setShowScrollTop(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
      <Toolbar />
      {/* Lista de herramientas externas movida arriba */}
      <List>
        <ListItem>
          <Typography variant="subtitle1">External Tools</Typography>
        </ListItem>
        {externalTools.map((tool) => (
          <ListItem key={tool.name} disablePadding>
            <ListItemButton component="a" href={tool.url} rel="noopener noreferrer">
              <ListItemText primary={tool.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* Lista de categorías */}
      <List>
        <ListItem disablePadding>
          <ListItemButton selected={currentCategory === 'all'} onClick={() => handleCategoryChange('all')}>
            <ListItemText primary="All" />
          </ListItemButton>
        </ListItem>
        {Object.keys(toolsData).map((category) => (
          <ListItem key={category} disablePadding>
            <ListItemButton selected={currentCategory === category} onClick={() => handleCategoryChange(category)}>
              <ListItemText primary={category.charAt(0).toUpperCase() + category.slice(1)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            🐑  {/* Icono lamb */}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {isMobile ? (
            <SwipeableDrawer
              variant="temporary"
              open={mobileOpen}
              onOpen={handleDrawerToggle}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {drawer}
            </SwipeableDrawer>
          ) : (
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - 240px)` } }}>
          <Toolbar />
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {filteredTools.map((tool, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <ToolCard tool={tool} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        {/* Botón de scroll to top */}
        {showScrollTop && (
          <IconButton
            onClick={scrollToTop}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ToolsDashboard;